// Here you can add other styles

.bg-danger {
  background-color: #e55353;
  color: white;
}

.bg-success {
  background-color: #2eb85c;
  color: white;
}

.btn-change-color {

  .btn-danger,
  .btn-success,
  .btn-info {
    color: white;
  }

  .btn-danger:hover,
  .btn-success:hover,
  .btn-info:hover {
    color: black;
  }
}

.sidebar-brand {
  background-color: white;
  -webkit-box-shadow: inset 0px 0px 8px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: inset 0px 0px 8px 0px rgba(0, 0, 0, 0.75);
  box-shadow: inset 0px 0px 8px 0px rgba(0, 0, 0, 0.75);
}

.sidebar-nav {
  background-color: black;
  .nav-link.active {
    background: #1f8181;
  }
}

.sidebar-toggler {
  background: #1f8181;
}
.sidebar-toggler:hover {
  background: #155858;
}