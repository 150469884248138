.rc-pagination-prev button:after,
.rc-pagination-next button:after {
  margin-top: -4px;
  font-size: 20px;
}

.rc-pagination-item-active:focus,
.rc-pagination-item-active:hover {
  border-color: #000;
}

.number-pagination.rc-pagination {
  text-align: center;
  padding: 15px 0;
}

.rc-pagination-item.rc-pagination-item-active {
  background: #000;
  border-color: #000;
  color: white;
}
